
import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes, useNavigate } from "react-router-dom";


import Auth from "./Components/Auth/Auth";
import Content from "./Components/Content/Content";


import { Toaster } from "react-hot-toast";


import { ReactSession } from 'react-client-session';
ReactSession.setStoreType("localStorage");




function App() {

  const currentUser = ReactSession.get("client_user"); 
  const atLogin = document.location.pathname.includes("/auth/login");
  const navigate = useNavigate();

  useEffect(() => {
    if (!atLogin && !currentUser) {
      navigate("/auth/login");
    }
  }, [navigate, atLogin, currentUser]);


  return (
    <div>
    <div><Toaster/></div>
    
    <Routes>
      <Route path="/auth/*" element={<Auth />} />
      <Route path="/*" element={<Content />} />
    </Routes>

  </div>
  );
}

export default App;
