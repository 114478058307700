import * as React from "react";
import Pagination from "../Core/Pagination";
import Moment from 'moment';

import logo from "../AuditList/Assets/Images/shield-icon.png"; //'/../Assets/Images/project_icon.png';

import { useState } from "react";
import { Button, Modal } from 'flowbite-react';

import "lightbox.js-react/dist/index.css"
import { SlideshowLightbox, initLightboxJS } from "lightbox.js-react"
import usePictureList from "./Hooks/usePictureLits";



const items = [
    "/images/portfolio/fleet1_iphone.jpeg",
    "/images/portfolio/fleet4_iphone.jpeg",
    "/images/portfolio/fleet5_iphone.jpeg"];


export default function PictureListView({ item }) {

 
  const { data, loading } = usePictureList(item.id);


  //if(data.length === 0)
  //return <>No Records</>;

  return ( loading ? <>Loading</>: data.length == 0 ? <>No Pictures</> :
    <>
        <SlideshowLightbox className="flex flex-wrap flex-row justify-center">
        {/*items.map((index, itemUrl) => {
        
            return (<Image className="w-full rounded" key={index} src={itemUrl} />);
        
        })*/}
            {data.map(image => (<img className="w-52 p-2 rounded" src={image} />))}
        {/* 
            <img className="w-52 p-2 rounded" src="images/portfolio/gps-tracker/fleet4_iphone.jpeg" />  
            <img className="w-52 p-2 rounded" src="images/portfolio/gps-tracker/fleet5_iphone.jpeg" />

            <img className="w-52 p-2 rounded" src="images/portfolio/qaqc/qaqc1_iphone.jpeg" />
            <img className="w-52 p-2 rounded" src="images/portfolio/qaqc/qaqc2_iphone.jpeg" />  
            <img className="w-52 p-2 rounded" src="images/portfolio/qaqc/qaqc8_iphone.jpeg" />
        */}
        </SlideshowLightbox> 
    </>
  );
}



