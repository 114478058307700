
import { useCallback, useState, useEffect } from "react";
import toast from "react-hot-toast";
import axios from 'axios';
import { ReactSession } from 'react-client-session';
ReactSession.setStoreType("localStorage");

export default function useAuditMetaList({ location, startDate, endDate, currentPage }) {



  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(null);


  useEffect(() => {
      setLoading(true);

      const currentUser = ReactSession.get("client_user"); 
      if(!currentUser)
      {
        setLoading(false);
        return;
      }

      const user = JSON.parse(currentUser);

  
      const serverUrl = "https://qaqcpro.azurewebsites.net/engine/call";

      var filters = [];
      if(location && location !== '0')
        filters.push({ Name:"Location", Value: location });
      if(startDate)
        filters.push({ Name:"StartDate", Value: startDate });
      if(endDate)
        filters.push({ Name:"EndDate", Value: endDate });



      const payload = { 

        FacilityId: user.facility, 
        UserId: user.id, 
        PageNumber:currentPage, 
        PageSize:20,
        Filters: filters
      };


      //console.log(payload);


      const requestBody = {
        Action: "audits/all",
        Payload: payload,
      };
      const response = axios.post(serverUrl, requestBody).then(response => {
        
        //console.log(response);
        setData(response.data);
        setLoading(false);
      }).catch(e =>{

        //console.log(e);

      });

 

  },[location, startDate, endDate, currentPage])

  return { data, loading }
}
