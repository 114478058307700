import moment from "moment";

import "react-dates/initialize";
import { SingleDatePicker, DateRangePicker } from "react-dates";

import "react-dates/lib/css/_datepicker.css";


import { useState } from "react";


export default function RangeDatePicker({ clearPagging, startDate, setStartDate, endDate, setEndDate }) {
  //const [startDate, setStartDate] = useState('');
  //const [endDate, setEndDate] = useState('');
  const [focus, setFocus] = useState(false);
  //console.log(date, focus);


  const [state, setState] = useState({
    calenderFocused: null,
    startDate: '',
    endDate: ''
  });

  const onDatesChange = ({ startDate, endDate }) => {
    setState({ startDate: startDate, endDate: endDate });

    
    setStartDate(startDate);
    setEndDate(endDate);

    clearPagging();
    //========
    //console.log(state);
  };


  const onFocusChange = calenderFocused => {
    setState({

      calenderFocused: calenderFocused
    });
  };


  return (
    <div>
   <label for="countries" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Dates</label>

      <DateRangePicker
  
        startDate={startDate} // momentPropTypes.momentObj or null
        endDate={endDate} 
        focusedInput={state.calenderFocused}
        //=====

        onDatesChange={onDatesChange} // PropTypes.func.isRequired
        onFocusChange={onFocusChange} // PropTypes.func.isRequired
        //====
         
          


        displayFormat="DD-MMM-YYYY"
        isOutsideRange={() => false}
        numberOfMonths={1}
        showClearDates={true}
    
      />


        {/* 
            <DateRangePicker
          small={true}
          startDate={this.props.filters.startDate}
          endDate={this.props.filters.endDate}
          onDatesChange={this.onDatesChange}
          focusedInput={this.state.calenderFocused}
          onFocusChange={this.onFocusChange}
          isOutsideRange={() => false}
          numberOfMonths={1}
          showClearDates={true}
          startDateId={this.state.startDateId}
          endDateId={this.state.endDateId}
        />

        */}

    </div>
  );
}