import * as React from 'react';
import { useState } from "react";

import Pagination from '../Core/Pagination';

import logo from "../AuditList/Assets/Images/shield-icon.png"; //'/../Assets/Images/project_icon.png';

import ItemView from "./ItemView";
import useAuditMetaList from './Hooks/useAuditMetaList';

import { Dropdown } from 'flowbite-react';
import RangeDatePicker from '../DatePicker/DatePicker';
import useLocationList from './Hooks/useLocationList';

export default function AuditList() {

  const [location, setLocation] = useState(null); 
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const [ currentPage, setCurrentPage ] = useState(1);
  
  


  const { data } = useAuditMetaList({ location, startDate, endDate, currentPage });
  const { loading, locations } = useLocationList();


  const onLocationChange = (e) => {
    var value = e.target.value;
    setLocation(value);
    ClearPagging();
  };

  const ClearPagging = () =>
  {
    setCurrentPage(1);

  };



  return (
  <div>



  <div className="flex flex-col container mx-auto w-full  bg-white dark:bg-gray-800 rounded-lg shadow">
  <div className="flex ">
  <div className="flex-none mx-3">
    <label for="locations" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Emplacement</label>
    <select id="locations" onChange={onLocationChange} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
    <option value={0} selected>All</option>
    {!loading && locations.map(loc => <option value={loc.id}>{loc.name}</option>)}
    </select>
  </div>
  <div className="flex-initial">
    <RangeDatePicker clearPagging={ClearPagging}  startDate={startDate} setStartDate={setStartDate} endDate={endDate} setEndDate={setEndDate} />
  </div>
  </div>


  <ul className="flex mt-5 flex-col divide-y w-full">

  {data?.items?.map((item) => (
         <ItemView item={item} />
    ))}

  </ul>
  </div>
  <div className="flex pt-4 justify-center">
  <Pagination totalCount={data.totalCount} currentPage={currentPage} setCurrentPage={setCurrentPage} />
  </div>
  </div>
  );
}