import * as React from 'react';

import useLogin from "./Hooks/useLogin";
import logo from "../Assets/Images/logo.png"; 
import toast from 'react-hot-toast';
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { ReactSession } from 'react-client-session';
import { useForm } from "react-hook-form";
import DottedSpinner from "../../Core/DottedSpinner/DottedSpinner";


export default function Login() {

    const navigate = useNavigate();
    const form = useForm();
    const { register, handleSubmit, watch, formState } = form;
    const { errors } = formState;
    const formValues = watch();
    const { login, loading } = useLogin(formValues);

 
  
    const handleSignup = ()=>
    {
        toast.success("Not implemented");
    };

    const handleForgotPassword = ()=>
    {
        toast.success("Not implemented");
    };


  return (
<section component="form" onSubmit={handleSubmit(login)} class="bg-gray-50 dark:bg-gray-900">
  <div class="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
      <a href="#" class="flex items-center mb-6 text-2xl font-semibold text-gray-900 dark:text-white">
          <img class="w-20 h-20 mr-2" src={logo} alt="logo" />
          Trimax Security    
      </a>
      <div class="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
          <div class="p-6 space-y-4 md:space-y-6 sm:p-8">
              <h1 class="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                  Sign in to your account
              </h1>
              <form class="space-y-4 md:space-y-6" action="#">
                  <div>
                      <label for="email" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Your email</label>
                      <input 
                      {...register("email", { required: "Email is required" })}
                      type="email" name="email" id="email" class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="name@company.com" required="" />
                  </div>
                  <div>
                      <label for="password" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Password</label>
                      <input 
                      {...register("password", { required: "Password is required" })}
                      type="password" name="password" id="password" placeholder="••••••••" class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required="" />
                  </div>

                  {/*
                  <div class="flex items-center justify-between">
                      <div class="flex items-start">
                          <div class="flex items-center h-5">
                            <input id="remember" aria-describedby="remember" type="checkbox" class="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-primary-600 dark:ring-offset-gray-800" required="" />
                          </div>
                          <div class="ml-3 text-sm">
                            <label for="remember" class="text-gray-500 dark:text-gray-300">Remember me</label>
                          </div>
                      </div>
                      <a href="#" onClick={handleForgotPassword} class="text-sm font-medium text-primary-600 hover:underline dark:text-primary-500">Forgot password?</a>
                  </div>
                  */}
                  <button  type="submit" class="w-full text-white bg-gray-800 hover:bg-gray-900 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">
                    {loading ? <DottedSpinner /> : <span>Sign In</span>}
                  </button>
                  {/* 
                  <p class="text-sm font-light text-gray-500 dark:text-gray-400">
                      Don’t have an account yet? <a href="#" onClick={handleSignup} class="font-medium text-primary-600 hover:underline dark:text-primary-500">Sign up</a>
                  </p>
                  */}
              </form>
          </div>
      </div>
  </div>
</section>
     

  );
}