import React, { useEffect } from 'react';
import { ReactSession } from 'react-client-session';


import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import AuditList from '../AuditList/AuditList';


import Login from "../Auth/Login/Login";
import Footer from '../Core/Footer';
import Header from '../Core/Header';
import Home from '../Home/Home';

export default function Content() {



  return (
      <div>
        <Header />
        <Routes>
                <Route path="/" element={<AuditList />} />
                <Route path="/audits" element={<AuditList />} />
                <Route path="/home" element={<Home />} />
        </Routes>
        <Footer />
      </div>

  );
}