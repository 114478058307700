
import { useCallback, useState, useEffect } from "react";
import toast from "react-hot-toast";
import axios from 'axios';
import { ReactSession } from 'react-client-session';
ReactSession.setStoreType("localStorage");

export default function usePictureList(itemId) {



  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(null);


  useEffect(() => {
      setLoading(true);

  
      const serverUrl = "https://qaqcpro.azurewebsites.net/engine/call";

      const payload = { 
        ItemId: itemId
      };

      //console.log(payload);

      const requestBody = {
        Action: "audits/one/images",
        Payload: payload,
      };
      const response = axios.post(serverUrl, requestBody).then(response => {
        
        //console.log(response);
        setData(response.data);
        setLoading(false);
      }).catch(e =>{

        //console.log(e);

      });

 

  },[itemId])

  return { data, loading }
}
