
import { useCallback, useState, useEffect } from "react";
import toast from "react-hot-toast";
import axios from 'axios';
import { ReactSession } from 'react-client-session';
ReactSession.setStoreType("localStorage");


export default function useLocationList() {



  const [locations, setLocations] = useState([]);
  const [loading, setLoading] = useState(null);


  useEffect(() => {
      setLoading(true);

      const currentUser = ReactSession.get("client_user"); 
      if(!currentUser)
      {
        setLoading(false);
        return;
      }

      const user = JSON.parse(currentUser);

      const serverUrl = "https://qaqcpro.azurewebsites.net/engine/call";
      const payload = { FacilityId: user.facility, UserId: user.id };
      //console.log(payload);
      const requestBody = {
        Action: "locations/all",
        Payload: payload,
      };
      const response = axios.post(serverUrl, requestBody)
      .then(response => {
        //console.log(response);
        setLocations(response.data);
        setLoading(false);
      })
      .catch(e =>
      {
        //console.log(e);
      });

  },[])

  return { locations, loading }
}