import * as React from "react";
import Pagination from "../Core/Pagination";
import Moment from 'moment';

import logo from "../AuditList/Assets/Images/shield-icon.png"; //'/../Assets/Images/project_icon.png';

import { useState } from "react";
import { Button, Modal } from 'flowbite-react';
import PictureListView from "./PictureListView";

export default function ItemModelView({ item, show, setShow }) {

 //const [show, setShow] = useState(false); 

 const onClick = () => {

    setShow(true);


 };

 const onClose = () => {

    setShow(false);


 };

  return (

<>
{/*<Button onClick={onClick}>
    Toggle modal
  </Button>*/}
  <Modal
    show={show}
    onClose={onClose}
  >
    <Modal.Header>
      {item.title}
    </Modal.Header>
    <Modal.Body>
      <div className="space-y-6">
        <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
        </p>
        {show ? <PictureListView item={item} /> : <></>}
      </div>
    </Modal.Body>
    <Modal.Footer>
    
    </Modal.Footer>
  </Modal>
    </>
  );
}



