
import * as React from 'react';
import { useState } from "react";

export default function Pagination({ totalCount, currentPage, setCurrentPage }) {




  const pageSize = 20;

  var totalRecords = totalCount;
  if(totalRecords > 500)
    totalRecords = 500;

  const lastIndex = currentPage * pageSize;
  const firstIndex = lastIndex - pageSize;
  const npage = Math.ceil(totalRecords / pageSize);


  var numbers = [1];
  if(totalCount >0)
  numbers = [ ...Array(npage + 1).keys()].slice(1);



  const previousPage = () =>
  {
    if(currentPage !== 1)
    {
      setCurrentPage(currentPage - 1);
    }
  };

  const changePage = (id) =>
  {
    setCurrentPage(id);
  };

  const nextPage = () =>
  {
    if(currentPage !== npage)
    {
      setCurrentPage(currentPage + 1);
    }
  };




  return (
<nav  aria-label="Page navigation example">
  <ul class="inline-flex -space-x-px">
    <li>
      <a 
      href="#" 
      onClick={() => previousPage()}
      class="py-2 px-3 ml-0 leading-tight text-gray-500 bg-white rounded-l-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">Back</a>
    </li>

    {numbers.map((n,i) => 
    <li>
      <a 
        href="#" 
        onClick={() => changePage(n)}
        className={`${currentPage === n ? 'bg-blue-100 hover:bg-blue-100':''} py-2 px-3 leading-tight text-gray-500 border border-gray-300 hover:bg-gray-100 hover:text-gray-700`}>{n}</a>
    </li>)
    }

    <li>
      <a 
        href="#" 
        onClick={() => nextPage()}
        className="py-2 px-3 leading-tight text-gray-500 bg-white rounded-r-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">Next</a>
    </li>
  </ul>
</nav>

  );
}




