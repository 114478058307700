import * as React from 'react';
import toast from 'react-hot-toast';

import logo from "../Auth/Assets/Images/logo.png"; //'/../Assets/Images/project_icon.png';

export default function Footer() {

    const handleNotImplemented = ()=>
    {
       
        toast.success("Not implemented");
    };

  return (
<footer class="p-4 bg-white rounded-lg shadow md:flex md:items-center md:justify-between md:p-6 dark:bg-gray-800">
    <span class="text-sm text-gray-500 sm:text-center dark:text-gray-400">© 2022 <a href="#" class="hover:underline">Trimax Security</a>. All Rights Reserved.
    </span>
    <ul class="flex flex-wrap items-center mt-3 text-sm text-gray-500 dark:text-gray-400 sm:mt-0">
        <li>
            <a href="#" onClick={handleNotImplemented} class="mr-4 hover:underline md:mr-6 ">About</a>
        </li>
        <li>
            <a href="#" onClick={handleNotImplemented} class="mr-4 hover:underline md:mr-6">Privacy Policy</a>
        </li>
        <li>
            <a href="#" onClick={handleNotImplemented} class="mr-4 hover:underline md:mr-6">Licensing</a>
        </li>
        <li>
            <a href="#" onClick={handleNotImplemented} class="hover:underline">Contact</a>
        </li>
    </ul>
</footer>

  );
}