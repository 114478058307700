
import { useCallback, useState } from "react";
import toast from "react-hot-toast";

import { useNavigate } from "react-router-dom";


import axios from "axios";

import { ReactSession }  from 'react-client-session';
ReactSession.setStoreType("localStorage");


export default function useLogin(formValues) {

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const login = useCallback(async () => {
    try {
      setLoading(true);

      // POST request using axios with async/await
      const serverUrl = "https://qaqcpro.azurewebsites.net/engine/call";
      const requestBody = {
        Action: "auth/login",
        Payload: { Email: formValues.email, Password: formValues.password },
      };
      const response = await axios.post(serverUrl, requestBody);
      const operationResponse = response.data;

      if(!operationResponse.success)
      {
        toast.error(operationResponse.message);
        return;
      }

      const currentUser = operationResponse.user;
      ReactSession.set("client_user", JSON.stringify(currentUser));

      navigate("/");
      
    } catch (e) {
      toast.error(e.message);
    } finally {
      setLoading(false);
    }
  }, [formValues, navigate]);

  return { login, loading };
}
