import * as React from "react";
import Pagination from "../Core/Pagination";
import Moment from 'moment';

import logo from "../AuditList/Assets/Images/shield-icon.png"; //'/../Assets/Images/project_icon.png';

import ItemModelView from "./ItemModelView";
import { useState } from "react";

export default function ItemView({ item }) {

  const [show, setShow] = useState(false); 

  const onClick = (event) => {

    if(event.target.innerText === "Download")
    return;

    setShow(true);
  };

  return (
    <>
    <li class="flex flex-row">
      <div onClick={onClick}  class="select-none cursor-pointer hover:bg-gray-50 flex flex-1 items-center p-4">
        <div class="flex flex-col w-10 h-10 justify-center items-center mr-4">
          <a href="#" class="block relative">
            <img
              alt="profil"
              src={logo}
              class="mx-auto object-cover h-10 w-10"
            />
          </a>
        </div>
        <div class="flex-1 pl-1">
          <div class="font-medium dark:text-white text-sm">Rapport</div>
          <div class="text-gray-600 dark:text-gray-200 text-sm">
            {item.title}
          </div>
        </div>

        <div class="flex-1 pl-1">
          <div class="font-medium dark:text-white text-sm">Emplacement</div>
          <div class="text-gray-600 dark:text-gray-200 text-sm">
            {item.location}
          </div>
        </div>

        {/*
      <div class="flex flex-col w-10 h-10 justify-center items-center mr-4">
        <a href="#" class="block relative">
          <img alt="profil" src="https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=150&q=80" class="mx-auto object-cover rounded-full h-10 w-10" />
        </a>
      </div>
      */}

        {/* 
      <div class="flex-1 pl-1">
        <div class="font-medium dark:text-white text-sm">User</div>
        <div class="text-gray-600 dark:text-gray-200 text-sm">{item.userName}</div>
      </div>
      */}

        <div class="flex-1 pl-1 mx-2">
          <div class="font-medium dark:text-white text-sm">Description</div>
          <div class="text-gray-600 dark:text-gray-200 text-sm">
            {item.description}
          </div>
        </div>
        <div class="flex-1 pl-1 mx-2">
          <div class="font-medium dark:text-white text-sm">Date</div>
          <div class="text-gray-600 dark:text-gray-200 text-sm">
          {Moment(item.auditDate).format('ll')}
        
          </div>
        </div>

        <div class="flex flex-row justify-center">
          <a
            href={
              "https://manage.trimaxapp.com/Trimax/Main/ExportToPdf?customId=" +
              item.customId
            }
            type="button"
            class="focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
          >
            Download
          </a>

    
        </div>
      </div>
    </li>
    <ItemModelView item={item} show={show} setShow={setShow}  />
    </>
  );
}
